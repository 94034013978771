import * as logger from "@/tools/logger.js";
import * as statusMapper from "@/service/error_request_mapper.js";

const _FILE_PATH = "service/dictionary/work_duration_reason_service.js";
const _M_GET_ALL = "getAllWorkDurationReasons";
const _M_GET_BY_ID = "getWorkDurationReasonById";

/** Service d'accès à l'API des motifs temps de travail <24h */
export default class WorkDurationReasonService {
  constructor(apis) {
    this.apis = apis;
    this.api = this.apis.getWorkDurationReasonApi();
  }

  /////////////////////////////// GET /////////////////////////////////////////
  /**
   * Récupère la liste des motifs temps de travail et les tri par ordre alphabétique
   */
  async getAllWorkDurationReasons() {
    logger.debugFull(
      _FILE_PATH,
      _M_GET_ALL,
      "Récupération de tous les motifs temps de travail."
    );

    return this.api
      .getAll()
      .then((data) => {
        data.sort(function(a, b) {
          return a.label.localeCompare(b.label);
        });
        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  /**
   * Récupère un motif maladie par son id
   */
  async getWorkDurationReasonById(id) {
    logger.debugFull(
      _FILE_PATH,
      _M_GET_BY_ID,
      "Récupération du motif temps de travail avec l'id : " + id
    );

    return this.api.getById(id).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /////////////////////////////// CREATE /////////////////////////////////////////

  async create(reason) {
    return this.api.create(reason).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(reason.id);
      converter.add409AlreadyExists(reason.name);
      converter.convert(error);
    });
  }

  /////////////////////////////// UPDATE /////////////////////////////////////////

  async update(reason) {
    return this.api.update(reason).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(reason.id);
      converter.convert(error);
    });
  }

  /////////////////////////////// DELETE /////////////////////////////////////////

  async delete(reasonId) {
    return this.api.delete(reasonId).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(reasonId);
      converter.convert(error);
    });
  }
}
