<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Motif temps de travail <24h" />
          </div>

          <div class="d-flex justify-center " >
            <WarningEditComponent/>
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Détail du motif de temps de travail</div>
              </v-row>
            </v-card-title>

            <!-- le label -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">label</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    dense
                    v-model="label"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!hasChanged"
                @click="save"
              >
                <div class="capitalize">
                  enregistrer
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- Fenetre modale pour éviter que l'utilisateur quitte sans sauvegarder -->
    <AlertNotSavedModifsComponent
    :show = "showAlertQuit"
    @quit = "onQuitAlert"
    @notquit = "onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import WarningEditComponent from "@/components/ui/WarningEditComponent.vue";

import WorkDurationReasonService from "@/service/dictionary/work_duration_reason_service.js";

import { areTheSame } from "@/tools/string_tool.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "EditWorkDurationReason",
  components: { Workflow, TitleAndReturnComponent, WarningEditComponent, AlertNotSavedModifsComponent },

  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin, AlertNotSavedModifsMixin],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      worksDurationReasonService: null,

      /** l'identifiant à éditer */
      workDurationReasonId: null,

      /**La donnée originale */
      source: null,

      /**le label */
      label: null,

      /**le code */
      code: null,
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        let reason = await this.worksDurationReasonService.getWorkDurationReasonById(this.workDurationReasonId);

        this.source = JSON.parse(JSON.stringify(reason));

        this.init();

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    init() {
      this.label = this.source.label;
    },

    async save() {
      try {
        let element = JSON.parse(JSON.stringify(this.source));

        element.label = this.label;

        await this.worksDurationReasonService.update(element);

        this.source = JSON.parse(JSON.stringify(element));

        this.disableAlertQuit();
        this.$router.go(-1);
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "enregistrement : " +
            (exceptions.toMessage(error) || "problème technique")
        );
      }
    },
  },
  computed: {
    completed() {
      if (!this.label) return false;

      return true;
    },
    hasChanged() {
      if (!this.source) return false;

      if (!this.completed) return false;

      let changed = false;

      if (!areTheSame(this.source.label, this.label)) {
        changed = true;
      }

      return changed;
    },
  },
  mounted() {
    // Instanciation de la classe service
    this.worksDurationReasonService = new WorkDurationReasonService(this.$api);

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.workDurationReasonId = this.$route.params.id;
    }

    this.load();
  },
};
</script>

<style>
</style>